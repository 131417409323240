import React from 'react'
import loadable from "@loadable/component"
// import Slider from 'react-slick';
import H2 from '../../core/H2';
const Slider = loadable(() => import("react-slick"))

const settings = {
  className: "center",
  arrows: false,
  focusOnSelect: false,
  useTransform: false,
  speed: 2000,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  infinite: true,
  pauseOnHover: true,
  swipe: false

}
export default function LogoSlider({allWpLogo:{nodes},title}) {
  return (
    <div className="overflow-hidden w-full py-20 bg-white px-4 md:px-6 lg:px-8" >
    {title &&<div data-aos="fade-up"> <H2 title={title} className={`text-center pb-16`} /></div>}

    <div data-aos="fade-up" >
      <Slider {...settings}>
        {nodes.map(({LogoCPT:{image:{ mediaItemUrl, altText, mediaDetails, srcSet }}}) => {
          return (
            <div key={Math.random()}>
             <div className="px-10 w-48 h-auto flex justify-center">
                <img
                  src={mediaItemUrl}
                  // srcset={srcSet}
                  alt={altText}
                  // title={altText?altText:"Logo Cloud"}
                  loading="lazy"
                  className="w-40 h-auto"
                  width={mediaDetails?.width}
                  height={mediaDetails?.height}
                />
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  </div>
  )
}
